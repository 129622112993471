import useSWR from 'swr';
import { getCookie } from 'tiny-cookie';
import { IAvatarHistory } from './interfaces/avatarHistory';
import { ISession } from './interfaces/session';
import { aresClient, aresFetcher, getAuthHeaders } from './sdk/client';
import { notLoggedInHandler, useUser } from './useBoot';
export * from './useBoot';

export let getToken = async () => {
  let token = getCookie('token');
  if (!token) notLoggedInHandler();
  return token;
};

export let useSessions = () => {
  let { data, error, revalidate } = useSWR<{ sessions: ISession[] }>(
    '/account/sessions',
    aresFetcher
  );

  let logout = async (sessionId: string) => {
    await aresClient.delete(`/account/session/${sessionId}/logout`, {
      headers: await getAuthHeaders()
    });

    return revalidate();
  };

  return {
    logout,
    sessions: data?.sessions || [],
    error,
    loading: !data && !error
  };
};

export let useProfilePictureHistory = () => {
  let { revalidate } = useUser();

  let { data, error } = useSWR<{ history: IAvatarHistory[] }>(
    '/account/profilePicture/history',
    aresFetcher
  );

  let setProfilePicture = async (id: string) => {
    await aresClient.post('/account/profilePicture/set/' + id, undefined, {
      headers: await getAuthHeaders()
    });

    revalidate();
  };

  return {
    history: data?.history || [],
    error,
    setProfilePicture,
    loading: !data && !error
  };
};
