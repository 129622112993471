import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/nextjs';
import { config } from '../../../config';
import { getToken, notLoggedInHandler } from '../useUser';

let aresClient = axios.create({ baseURL: config.aresService });
axiosRetry(aresClient, { retries: 2 });

export let getAuthHeaders = async () => {
  let token = await getToken();
  if (!token) return {};

  return {
    Authorization: 'Bearer ' + token,
    'x-highloop-token': token
  };
};

export let aresFetcher = async (url: string) =>
  aresClient
    .get(url, {
      headers: await getAuthHeaders()
    })
    .then(r => r.data)
    .catch(err => {
      if (err?.response?.status == 401) {
        notLoggedInHandler();
      } else {
        Sentry.captureException(err);
        throw err;
      }
    });

export { aresClient };
