import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import * as Sentry from '@sentry/nextjs';
import { config } from '../../config';
import { aresClient, aresFetcher, getAuthHeaders } from './sdk/client';
import { IUser } from './interfaces/user';
import { IProject } from './interfaces/project';

export let useBoot = () => {
  let [updating, setUpdating] = useState(false);
  let { data, error, revalidate, mutate } = useSWR<{ user: IUser; projects: IProject[] }>(
    `/boot`,
    aresFetcher
  );

  let updateUser = async (d: { name: string; email: string }) => {
    setUpdating(true);

    let res = await aresClient
      .put('/account', d, {
        headers: await getAuthHeaders()
      })
      .finally(() => setUpdating(false));

    mutate({ ...data, user: { ...data.user, ...res.data.user } });
    setUpdating(false);
  };

  let updateProfilePicture = async (d: { image: string }) => {
    setUpdating(true);

    let res = await aresClient
      .put('/account/profilePicture', d, {
        headers: await getAuthHeaders()
      })
      .finally(() => setUpdating(false));

    mutate({ ...data, user: { ...data.user, ...res.data.user } });
    setUpdating(false);
  };

  let resetProfilePicture = async () => {
    setUpdating(true);

    let res = await aresClient
      .delete('/account/profilePicture', {
        headers: await getAuthHeaders()
      })
      .finally(() => setUpdating(false));

    mutate({ ...data, user: { ...data.user, ...res.data.user } });
    setUpdating(false);
  };

  useEffect(() => {
    if (data?.user) {
      Sentry.setUser({
        id: data.user.id,
        email: data.user.email,
        name: data.user.name,
        photoUrl: data.user.photoUrl
      });
    }
  }, [data?.user]);

  return {
    user: data?.user,
    revalidate,
    projects: data?.projects,
    error,
    loading: !data && !error,
    updating,
    updateUser,
    updateProfilePicture,
    resetProfilePicture
  };
};

export let useUser = () => {
  let {
    user,
    error,
    loading,
    revalidate,
    updating,
    updateUser,
    updateProfilePicture,
    resetProfilePicture
  } = useBoot();

  return {
    user,
    error,
    loading,
    revalidate,
    updating,
    update: updateUser,
    updateProfilePicture,
    resetProfilePicture
  };
};

export let useProjects = () => {
  let { projects, error, loading, revalidate } = useBoot();
  return { projects, error, loading, revalidate };
};

export let useNotLoggedIn = () => {
  useUser();
};

export let notLoggedInHandler = () => {
  location.replace(
    config.authFrontend +
      '/login?next=' +
      encodeURIComponent(
        location.protocol + '//' + location.host + location.pathname + location.search
      )
  );
};

export let MustBeLoggedIn = () => {
  useNotLoggedIn();

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `if (!document.cookie || document.cookie.indexOf('token=') === -1) {
            location.replace('${config.authFrontend}/login?next=' + encodeURIComponent(location.protocol + '//' + location.host + location.pathname + location.search))
          } else {
            document.documentElement.classList.add('render')
          }`
        }}
      ></script>
    </Head>
  );
};
