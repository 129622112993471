import { styled } from 'buttered';
import React, { useState } from 'react';
import { Skeleton, toast } from '@vapor/ui';
import { AvatarEditor } from '@highloop-pkg/avatar-selector';
import { useUser } from '../state/useBoot';
import delay from 'delay';

let Wrapper = styled('button')`
  background: var(--accent-0);
  border: none;
  border-radius: 50%;
  height: 130px;
  width: 130px;
  display: flex;
  padding: 0px;
  margin: 0px;
  transition: all 0.3s;
  outline: none;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0 3px var(--vapor-primary);
  }
`;

export let Avatar = () => {
  let { user, updateProfilePicture, resetProfilePicture } = useUser();
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AvatarEditor
        avatarHistoryPage="/avatar-history"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onReset={() =>
          Promise.all([resetProfilePicture(), delay(900)])
            .then(() => {
              toast.success('Profile picture updated');
            })
            .catch(err => {
              toast.error('Could not reset profile picture');
            })
        }
        onSave={(image: string) =>
          Promise.allSettled([updateProfilePicture({ image }), delay(900)])
            .then(() => {
              toast.success('Profile picture updated');
            })
            .catch(err => {
              toast.error('Could not save image');
            })
        }
        photoUrl={user.photoUrl}
        label="Profile Picture"
        description={
          <>
            Set your profile picture
            <br />
            to make things more personal.
          </>
        }
        hint="Only visible for people in your projects"
        accept={['image/jpeg', 'image/png', 'image/jpg', 'image/webp']}
      />

      <Wrapper onClick={() => setIsOpen(true)}>
        {user && <img src={user.photoUrl} alt={user.name} />}
      </Wrapper>
    </>
  );
};

export let LoadingAvatar = () => {
  return (
    <Skeleton loading radius={500}>
      <Wrapper />
    </Skeleton>
  );
};
