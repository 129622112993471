export let config = {
  currentUrl: 'https://account.highloop.co',

  authService: 'https://auth.onhighloop.com/api',
  authFrontend: 'https://auth.onhighloop.com',
  aresService: 'https://ares.onhighloop.com',
  projectFrontend: 'https://projects.highloop.co',
  startFrontend: 'https://start.highloop.co',
  accountFrontend: '/'
};

if (process.env.NODE_ENV === 'development') {
  config = {
    currentUrl: 'http://localhost:3101',

    authService: 'http://localhost:3100/api',
    authFrontend: 'http://localhost:3100',
    aresService: 'http://localhost:4102',
    startFrontend: 'http://localhost:3106',
    projectFrontend: 'http://localhost:3102',
    accountFrontend: '/'
  };
}
