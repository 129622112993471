import { Button, Menu, Spinner, Switch, useSwitch, VerticalCenter } from '@vapor/ui';
import { keyframes, styled } from 'buttered';
import clsx from 'clsx';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import useDelayed from 'use-delayed';
import flatten from 'lodash/flatten';

let Wrapper = styled('div')`
  max-width: 1180px;
  margin: 120px auto 20px auto;
  display: grid;
  grid-template-columns: 230px 1fr;
  gap: 100px;
  padding: 0px 15px 0px 5px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 200px 1fr;
    gap: 35px;
    margin: 50px auto 20px auto;
  }

  @media screen and (max-width: 730px) {
    grid-template-columns: 100%;
    margin: 30px auto 20px auto;
    padding: 0px 20px;
  }
`;

let Sidebar = styled('aside')`
  @media screen and (max-width: 730px) {
    display: none;
  }
`;

let MobileNav = styled('aside')`
  @media screen and (min-width: 730px) {
    display: none;
  }
`;

let Nav = styled('nav')``;

let Main = styled('main')``;

let Header = styled('header')`
  padding: 15px 0px 10px 20px;

  p {
    padding-top: 6px;
    border-top: solid 1px #d7d7d7;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.6;
  }
`;

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

let fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

let ItemWrapper = styled('button')`
  display: grid;
  grid-template-columns: 4px 1fr;
  gap: 3px;
  cursor: pointer;
  padding: 0px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;

  & > div {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    gap: 5px;
    color: black;
    transition: all 0.3s;
    padding: 2px 4px;
    border-radius: 6px;
    min-height: 34px;
    pointer-events: none;
    user-select: none;

    figure {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    p {
      font-size: 13px;
      font-weight: 500;
      opacity: 0.7;
    }
  }

  &.subitem {
    & > div {
      grid-template-columns: 100%;
      padding-left: 39px;
    }
  }

  &.active {
    & > div {
      background: var(--vapor-accent-2);
    }
  }

  &:hover,
  &:focus {
    & > div {
      background: var(--vapor-accent-3);
    }
  }

  span {
    transition: all 0.3s;
    width: 4px;
    height: calc(100% - 10px);
    border-radius: 10px;
    margin: 5px 0px;
  }

  &.active {
    span {
      background: var(--vapor-primary);
    }
  }
`;

let SwitchWrapper = styled('div')`
  width: fit-content;
  margin: 0px auto 30px auto;
  height: 30px;
  padding-left: 10px;
`;

let SubItemWrapper = styled('div')``;

let HR = styled('hr')``;

export interface IItem {
  id: string;
  name: string;
  type: 'header' | 'item' | 'separator';
  icon?: React.ReactNode;
  active?: boolean;
  subitems?: {
    id: string;
    name: string;
    active?: boolean;
  }[];
}

let Item = ({ item, onItemClick }: { item: IItem; onItemClick: (id: string) => void }) => {
  let [isOpen, setIsOpen] = useState(false);
  let canRenderSubitems = useDelayed(isOpen, 500, [true]);

  return (
    <>
      <ItemWrapper
        className={clsx({ active: item.active })}
        onClick={() => {
          if (item.subitems) setIsOpen(!isOpen);
          else onItemClick(item.id);
        }}
      >
        <span />
        <div>
          <figure>{item.icon}</figure>

          <VerticalCenter>
            <p>{item.name}</p>
          </VerticalCenter>

          {item.subitems && (
            <figure
              style={{ transition: 'all .3s', transform: `rotate(${isOpen ? 180 : 0}deg)` }}
            >
              <ChevronDown style={{ height: 12, width: 12 }} />
            </figure>
          )}
        </div>
      </ItemWrapper>

      <SubItemWrapper
        className={clsx({ isOpen, active: item.active })}
        style={{ height: isOpen ? 34 * item.subitems.length : 0, transition: 'all .3s' }}
      >
        <div style={{ animation: `${isOpen ? fadeIn : fadeOut} .2s ease-in-out forwards` }}>
          {item.subitems &&
            canRenderSubitems &&
            item.subitems.map(subitem => (
              <ItemWrapper
                className="subitem"
                key={subitem.id}
                onClick={() => onItemClick(subitem.id)}
              >
                <span />
                <div>
                  <VerticalCenter>
                    <p>{subitem.name}</p>
                  </VerticalCenter>
                </div>
              </ItemWrapper>
            ))}
        </div>
      </SubItemWrapper>
    </>
  );
};

export let Navigation = ({
  sections,
  onItemClick,
  activeSection,
  children
}: {
  activeSection: string;
  onItemClick: (id: string) => void;
  sections: {
    id: string;
    name: string;
    items?: IItem[];
    externalUrl?: string;
  }[];
  children: React.ReactNode;
}) => {
  let switchState = useSwitch({
    options: useMemo(
      () =>
        sections.map(section => ({
          id: section.id,
          label: section.name
        })),
      [sections]
    ),
    onChange: key => {
      let section = sections.find(section => section.id === key);
      if (section.externalUrl) location.href = section.externalUrl;
    },
    defaultOption: activeSection
  });

  useEffect(() => switchState.setOption(activeSection), [activeSection]);

  let items = useMemo(() => {
    return sections.find(s => s.id === switchState.selectedOption.option.id)!.items || [];
  }, [sections, switchState.selectedOption]);

  let currentItem = useMemo(() => items.find(item => item.active), [items]);

  return (
    <Wrapper>
      <MobileNav>
        {sections.length > 1 && (
          <SwitchWrapper>
            <Switch state={switchState} />
          </SwitchWrapper>
        )}

        <Menu
          label="Demo Menu"
          action={id => onItemClick(id)}
          items={flatten(
            items.map(item =>
              item.type == 'item'
                ? item.subitems
                  ? item.subitems.map(item => ({
                      id: item.id,
                      type: 'item',
                      component: item.name
                    }))
                  : {
                      id: item.id,
                      type: 'item',
                      component: item.name
                    }
                : {
                    id: item.id,
                    type: 'separator'
                  }
            )
          )}
        >
          {({ attrs, ref }) => (
            <Button
              variant="secondary"
              iconRight={<ChevronDown />}
              size="small"
              {...attrs}
              ref={ref}
            >
              {currentItem?.name || 'Menu'}
            </Button>
          )}
        </Menu>
      </MobileNav>

      <Sidebar>
        {sections.length > 1 && (
          <SwitchWrapper>
            <Switch state={switchState} />
          </SwitchWrapper>
        )}

        <Nav>
          {items.length == 0 && (
            <div style={{ margin: '50px auto 0px auto', width: 'fit-content' }}>
              <Spinner />
            </div>
          )}

          {items.map(item => (
            <Fragment key={item.id}>
              {item.type === 'header' && (
                <Header>
                  <p>{item.name}</p>
                </Header>
              )}

              {item.type === 'item' && <Item onItemClick={onItemClick} item={item} />}

              {item.type === 'separator' && <HR />}
            </Fragment>
          ))}
        </Nav>
      </Sidebar>

      <Main>{children}</Main>
    </Wrapper>
  );
};
