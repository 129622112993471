import {
  Button,
  Input,
  Notice,
  Skeleton,
  Spacer,
  Text,
  toast,
  VerticalCenter
} from '@vapor/ui';
import { styled } from 'buttered';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import * as Yup from 'yup';
import { Avatar, LoadingAvatar } from '../components/avatar';
import { HR } from '../components/hr';
import { Layout } from '../layout';
import { useUser } from '../state/useUser';

let Header = styled('header')`
  display: grid;
  grid-template-columns: 130px auto;
  gap: 40px;

  @media screen and (max-width: 700px) {
    grid-template-columns: auto;
    gap: 10px;
  }
`;

let AccountSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, `A name can't be longer than 100 characters.`)
    .required('Looks like you forgot something...'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Looks like you forgot something...')
});

export default function Home() {
  let { user, updating, update, error: userError } = useUser();

  let formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || ''
    },
    validationSchema: AccountSchema,
    onSubmit: values => {
      update(values)
        .then(() => toast.success('Account updated successfully'))
        .catch(err => {
          Sentry.captureException(err);
          toast.error('Could not update account');
        });
    }
  });

  useEffect(() => {
    formik.setFieldValue('name', user?.name || '');
  }, [user?.name]);

  useEffect(() => {
    formik.setFieldValue('email', user?.email || '');
  }, [user?.email]);

  return (
    <Layout pageId="profile">
      {userError && (
        <>
          <Notice variant="error">Could not load your account. Please try again later.</Notice>

          <Spacer height="20px" />
        </>
      )}

      <Header>
        {user?.photoUrl ? <Avatar /> : <LoadingAvatar />}

        <div>
          <VerticalCenter>
            <Skeleton loading={!user} radius={8}>
              <Text size={36} weight={700} truncate>
                {user?.name || 'Loading...'}
              </Text>
            </Skeleton>

            <Spacer height={7} />

            <Skeleton loading={!user} radius={8}>
              <Text size={16} weight={500} truncate>
                {user?.email || 'Loading...'}
              </Text>
            </Skeleton>
          </VerticalCenter>
        </div>
      </Header>

      <Spacer height="max(3vh, 15px)" />

      <HR />

      <Spacer height="max(3vh, 15px)" />

      <Text size={22} weight={600}>
        Personal Details
      </Text>

      <Spacer height="max(1.5vh, 10px)" />

      <Text size={16}>
        This is your Highloop profile. Make sure its up to date, so your co-workers know who
        you are when they see a task assigned to you or a feedback submission you've commented
        on.
      </Text>

      <Spacer height="15px" />

      <form onSubmit={formik.handleSubmit} style={{ textAlign: 'left' }}>
        <Input
          label="Name"
          placeholder="What's your name"
          name="name"
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
          disabled={!user}
        />

        <Spacer height="10px" />

        <Input
          label="Email"
          placeholder="Enter your email"
          name="email"
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          disabled={!user}
        />

        {user?.newEmail && (
          <>
            <Spacer height="10px" />
            <Notice variant="notice">We sent a verification email to {user.newEmail}.</Notice>
          </>
        )}

        <Spacer height="20px" />

        <Button variant="primary" type="submit" loading={updating}>
          Save
        </Button>
      </form>
    </Layout>
  );
}
