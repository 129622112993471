import React from 'react';
import { BaseLayout } from '@highloop-pkg/layout';
import { config } from '../../config';
import { useUser } from '../state/useUser';
import { useRouter } from 'next/router';
import { Navigation } from '../components/navigation';
import { Grid, Lock, Settings, Smartphone } from 'react-feather';

export let Layout = ({ children, pageId }: { children: React.ReactNode; pageId: string }) => {
  let { user } = useUser();
  let { push, pathname } = useRouter();

  return (
    <>
      <BaseLayout
        name="Highloop Account"
        user={user}
        navItems={[
          {
            children: 'Teams',
            href: config.startFrontend
          },
          {
            children: 'Account',
            href: '/',
            active: true
          }
        ]}
      >
        <Navigation
          activeSection="team"
          sections={[
            {
              id: 'team',
              name: 'Team',
              items: [
                {
                  type: 'item',
                  icon: <Settings />,
                  name: 'Profile',
                  id: '/',
                  active: pageId == 'profile'
                },
                {
                  type: 'item',
                  icon: <Grid />,
                  name: 'Projects',
                  id: '/projects',
                  active: pageId == 'projects'
                },
                {
                  type: 'header',
                  name: 'Security',
                  id: 'security'
                },
                {
                  type: 'item',
                  icon: <Lock />,
                  name: 'Login Methods',
                  id: '/login',
                  active: pageId == 'login'
                },
                {
                  type: 'item',
                  icon: <Smartphone />,
                  name: 'Devices',
                  id: '/devices',
                  active: pageId == 'devices'
                }
              ]
            }
          ]}
          onItemClick={id => {
            push(`/${id}`);
          }}
        >
          {children}
        </Navigation>
      </BaseLayout>
    </>
  );
};
